import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {Link} from "gatsby";

const GestionPage = (props) => {
const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Gestión de fondos"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">Una sólida
                                    experiencia </h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">GESTIÓN
                                    DE FONDOS</h2>
                            </div>

                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section" className={'pb-2'}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 sm-margin-30px-bottom">
                                <img className="border-radius-5px" src={"/images/lca/gestion.jpg"} alt={''}/>
                            </div>
                            <div className="col-12 col-lg-5 col-md-6 offset-lg-1">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                Identificación y análisis</h5>
                                <p className="w-85 lg-w-90">En LCA analizamos y proponemos  estructuras innovadoras para fondos, así  como identificamos oportunidades únicas de inversión y de desinversión.
                                Nos involucramos a profundidad en las actividades de recaudación de fondos, así como en el diseño de estrategias para los agentes de colocación. </p>
                                <p className="w-85 lg-w-90">Damos un paso más de lo esperado y  trabajamos junto al gestor de fondos para elaborar políticas de inversión, asegurando que las operaciones del mismo siempre respalden sus objetivos.  </p>
                                <p className="w-85 lg-w-90">Nos motiva una cultura de divulgación transparente y de normas corporativas sanas.</p>


                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}

                {/* start section */}
                <section className="wow animate__fadeIn pt-6">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 sm-margin-30px-bottom">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                                Los fondos exigen la continua supervisión de sus carteras y un cuidadoso control de las inversiones. </h5>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-7">
                                <div className="row row-cols-1 row-cols-sm-2">
                                    {/* start counter item */}
                                    <div
                                        className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                            >Capacidad</h5>

                                        <p className="w-85 md-w-100">Nuestra trayectoria de resultados nos brinda una experiencia única en el sector.
Aprovechando esta fortaleza, en el año 2020 lanzamos el fondo  Latam Capital I.</p>
                                    </div>
                                    {/* end counter item */}
                                    {/* start counter item */}
                                    <div className="col counter-style-01 last-paragraph-no-margin">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                            >Seguimiento</h5>

                                        <p className="w-85 md-w-100">A nivel del proyecto de infraestructura, poseemos la capacidad para hacer segimiento del proyecto a través de todo su ciclo de vida.</p>
                                    </div>
                                    {/* end counter item */}
                                </div>
                            </div>
                            <div className="col-12">
                                <div
                                    className="w-100 h-1px bg-medium-gray margin-2-rem-top margin-2-rem-bottom sm-margin-5-rem-tb"/>
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                {/* start tab navigation */}
                                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom">
                                    <li className="nav-item"><a className="nav-link active" data-toggle="tab"
                                                                href="#planning-tab">Orígen del proyecto</a><span
                                        className="tab-border bg-gradient-black-blue"/></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab"
                                                                href="#research-tab">Desarrollo del proyecto</a><span
                                        className="tab-border bg-gradient-black-blue"/></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab"
                                                                href="#target-tab">Desempeño del proyecto</a><span
                                        className="tab-border bg-gradient-black-blue"/></li>

                                </ul>
                                {/* end tab navigation */}
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="planning-tab" className="tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/interior/op11.png"} className="w-90 sm-w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Orígen del proyecto</h5>
                                                <p className="w-85 lg-w-100">Identificamos  proyectos económica y financieramente viables, con gran experiencia  analizamos los factores de riesgo que afectan la calidad de crédito.</p>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="research-tab" className="tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/interior/op12.png"} className="w-90 sm-w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Desarrollo del proyecto</h5>
                                                <p className="w-85 lg-w-100">Monitoreamos los proyectos durante cada una de las  fases de construcción y verificamos su estricto complimiento de acuerdo a la planeación.</p>
                                                <a href="about-me.html"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="target-tab" className="tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/interior/nos11.png"} className="w-90 sm-w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                Desempeño del proyecto</h5>
                                                <p className="w-85 lg-w-100">Monitoreamos el proyecto durante su fase comercial o de explotación, garantizado que
                                                                            el proyecto haya sido ejecutado de forma exitosa. Una vez en operación
                                                                            supervisamos el servicio de la deuda y la distribución de excedentes de caja a sus propietarios.
                                                                                </p>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
            </>


        </Layout>
    )
}

export default GestionPage
